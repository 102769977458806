<template>
  <div>
    <v-app-bar
      app
      class="primary--text elevation-3"
      hide-on-scroll
      flat
      color="grisBarraApp"
      v-if="!showMenu()"
    >
      <v-app-bar-nav-icon @click="toggle = !toggle" color="primary"
        ><v-icon>mdi-dots-horizontal</v-icon></v-app-bar-nav-icon
      >

      <v-toolbar-title v-if="!toggle"
        ><v-img :src="logonormal" max-width="100"
      /></v-toolbar-title>
      <v-spacer v-else></v-spacer>
      <v-spacer></v-spacer>
      <v-toolbar-title class="m-0 p-0" v-if="showMenu()">
        <v-list class="p-0 m-0" color="transparent" style="height: 63px">
          <v-list-item two-line class="py-0 px-0 m-0">
            <v-list-item-avatar size="40" class="elevation-2 p-0 mt-0 mb-0">
              <img :src="foto()" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                style="font-size: 14px"
                class="indigo7--text font-weight-bold"
              >
                {{ usuarioDB.data.nombre }}
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 12px" class="white--text">{{
                usuarioDB.data.cargo
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-toolbar-title>

      <v-icon class="primary--text" @click="cerrarSesion">mdi-logout</v-icon>
    </v-app-bar>

    <v-app-bar
      app
      class="indigo--text"
      clipped-left
      flat
      style="height: 63px; border: 0px solid #000"
      color="grisBarraApp"
      v-else
    >
      <v-toolbar-title><v-img :src="logonormal" max-width="100" /></v-toolbar-title>
      <v-app-bar-nav-icon
        @click="toggle = !toggle"
        color="primary"
        style="margin-left: 90px"
      >
        <v-avatar color="indigo lighten-5">
          <v-icon class="primary--text"> mdi-dots-horizontal </v-icon>
        </v-avatar>
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title class="m-0 p-0" v-if="showMenu()">
        <v-list class="p-0 m-0" color="transparent" style="height: 63px">
          <v-list-item
            two-line
            class="py-0 px-0 m-0"
            @click="$router.push({ name: 'perfil' })"
          >
            <v-list-item-avatar size="40" class="elevation-2 p-0 mt-0 mb-0">
              <img :src="foto()" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                style="font-size: 14px"
                class="indigo--text font-weight-bold"
              >
                {{ usuarioDB.data.nombre }}
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 12px" class="grey--text">
                {{ usuarioDB.data.cargo }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-toolbar-title>
      <v-avatar color="indigo lighten-5">
        <v-icon class="primary--text" dark @click="cerrarSesion">mdi-logout</v-icon>
      </v-avatar>
    </v-app-bar>
    <v-navigation-drawer
      :class="ndrawer()"
      style="border-right: 0px"
      app
      v-model="toggle"
      :expand-on-hover="expandOnHover"
      :right="right"
      :permanent="permanent"
      clipped
    >
      <v-list dense nav class="p-0" style="border-right: 10px">
        <!--<v-list-item :class="miniVariant && 'p-0 m-0 elevation-5'">-->

        <v-list class="p-0 m-0 pt-0 mt-0" style="height: 63px" v-if="!showMenu()">
          <v-list-item two-line class="m-0">
            <v-list-item-avatar size="40" class="elevation-2 mt-0 mb-0">
              <img :src="user" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                style="font-size: 14px"
                class="indigo7--text font-weight-bold"
              >
                Cristian Olivares
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 12px" class="indigo8--text"
                >Ingeniero de desarrollo</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider style="border: 0px" class="pt-0"></v-divider>

        <!--Nuevo-->
        <v-list
          class="pl-2 pr-2 pt-0 pb-0"
          v-for="item in items"
          :key="item.name"
          v-model="item.active"
        >
          <v-subheader :key="item.sub" class="primary--text font-weight-bold">
            {{ item.sub }}
          </v-subheader>
          <div v-for="itemChildren in item.children">
            <!--<v-list-item-content>
              <v-list-item-title v-text="itemChildren.name"></v-list-item-title>
            </v-list-item-content>-->
            <v-list-group
              :key="itemChildren.name"
              v-model="itemChildren.active"
              :prepend-icon="itemChildren.icon"
              :to="itemChildren.link"
              no-action
              :group="itemChildren.grupo"
              v-if="length(itemChildren.children) > 0"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    class="font-weight-normal"
                    v-text="itemChildren.name"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="subItem in itemChildren.children"
                :key="subItem.name"
                active-class="primary--text"
                @click.prevent
                @click.stop
                :to="subItem.link"
                class="text-decoration-none"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="font-weight-normal"
                    v-text="subItem.name"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item
              :key="itemChildren.name"
              active-class="primary--text"
              :to="itemChildren.link"
              class="text-decoration-none"
              v-else-if="itemChildren.link"
            >
              <v-list-item-icon>
                <v-icon v-text="itemChildren.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-normal"
                  v-text="itemChildren.name"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>

        <!--Nuevo-->

        <!--<v-list class="p-2 pt-8">
          <v-list-group
            v-for="item in items"
            :key="item.name"
            v-model="item.active"
            :prepend-icon="item.icon"
            :to="item.link"
            no-action
            :group="item.grupo"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-normal"
                  v-text="item.name"
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="subItem in item.children"
              v-if="item.children.length > 0"
              :key="subItem.name"
              active-class="primary--text"
              @click.prevent
              @click.stop
              :to="subItem.link"
              class="text-decoration-none"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-normal"
                  v-text="subItem.name"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>-->
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "Lateral",
  props: {
    mensaje: String,
    tog: Boolean,
  },

  data() {
    return {
      drawer: true,
      nombrePersona: "",
      items: [],
      color: "purpura1",
      colors: ["primary", "blue", "success", "red", "teal"],
      right: false,
      permanent: false,
      miniVariant: this.mini(),
      expandOnHover: this.mini(),
      background: false,
      logo: require("@/assets/imagenes/logoblanco.png"),
      logonormal: require("@/assets/imagenes/logo.png"),
      user: require("@/assets/imagenes/perfilDefault.png"),
      toggle: this.showMenu(),
    };
  },
  methods: {
    ...mapActions(["cerrarSesion"]),
    length(items) {
      if (items) {
        return items.length;
      } else {
        return 0;
      }
    },
    foto() {
      return this.usuarioDB.data.foto && this.usuarioDB.data.foto != ""
        ? process.env.VUE_APP_BASE_URL + "/files/avatars/" + this.usuarioDB.data.foto
        : require("@/assets/imagenes/perfilDefault.png");
    },
    mini() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    showMenu() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return true;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
    ndrawer() {
      if (this.showMenu()) {
        return "lighten-2 fill-height white";
      } else {
        return "backgroundMenu lighten-2 fill-height";
      }
    },
  },
  created() {
    //this.items = this.permisosMenu.items
    this.items = this.itemsMenu;
    this.nombrePersona = this.usuarioDB.data.nombre;
    this.role = this.usuarioDB.data.role;
  },
  computed: {
    ...mapState(["usuarioDB", "itemsMenu"]),
  },
  watch: {},
};
</script>

<style>
.backgroundMenu {
  background: rgb(222, 229, 239);
  background: -moz-linear-gradient(
    0deg,
    rgba(222, 229, 239, 1) 0%,
    rgba(251, 252, 254, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(222, 229, 239, 1) 0%,
    rgba(251, 252, 254, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(222, 229, 239, 1) 0%,
    rgba(251, 252, 254, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dee5ef",endColorstr="#fbfcfe",GradientType=1);
}

.active {
  color: #ffffff;
}
.red_list .v-list-item-group .v-list-item--active {
  background-color: red;
  color: green;
}

.v-list .v-icon {
  color: #bdbdbd;
}
.v-list-group .v-list-item__content .v-list-item__title {
  color: #000;
}
.v-list .v-list-item--active .v-icon {
  color: #1c67fd;
}
.v-list .v-list-item--active .v-list-item__content .v-list-item__title {
  color: #1c67fd;
  font-weight: bold !important;
  font-size: 15px;
}
.v-navigation-drawer .v-navigation-drawer__border {
  width: 0px;
}
.navigation-drawer[data-booted="true"] {
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.v-toolbar__content {
  border-bottom: 1px solid #eeeeee !important;
  margin: 0px;
}
</style>
